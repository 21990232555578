<template>
  <div class="w-full h-full">
    <Promotions mode="admin" />
  </div>
</template>

<script>
import Promotions from "@/components/promotions";

export default {
  name: 'PromotionsView',
  components: {
    Promotions
  }
}
</script>